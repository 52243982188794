// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Philosophy | Learning Wings Education System',
  nakedPageSlug: 'philosophy',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Philosophy">
        Philosophy
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          Learning Wings believes in quality, affordable and holistic education.
          We believe that it is our responsibility and duty as educators to live
          up to the best standards, and to go beyond. We believe that a good
          education can impact lives and open doors to dreams and things
          unimaginable. We believe that children are our future and providing
          them a good education is our obligation. We try our best to provide
          equal opportunities to as many children as we can. This is what drives
          Learning Wings. The mission of Learning Wings Education Systems is to
          nurture the development of responsible, thoughtful citizens for life
          in an increasingly interdependent global society by creating
          environments in which students are challenged to explore, to create
          and to make decisions.
        </p>
        <h2 className="mask-h4">1. Quality Education</h2>
        <p>
          Learning Wings imparts meaningful and relevant education built upon 30
          years of teaching & learning experience. Learning Wings has been
          consistently recognised as a leader in innovative and creative
          educational practices. Learning Wings has been awarded for best
          learning-teaching practices, including "Inclusive Education". Awards
          include Quality Pre school by Early Childhood Association (ECA).
          Learning Wings works together with University of Cambridge, Early
          Childhood Association, Teachers Education Colleges and many more.
        </p>
        <h2 className="mask-h4">2. Nurture Socially Aware Children</h2>
        <p>
          Learning Wings believes that our children are our future, and we teach
          them to be responsible socially while they are young. Learning Wings
          students regularly participate in social outreach programs. Learning
          Wings has played an integral part in programs such as Goonj, Satsang
          Vidyalaya, Walk of Hope and Manav Ekta Mission.
        </p>
        <h2 className="mask-h4">3. Empower Teachers</h2>
        <p>
          Learning Wings continuously undertakes teachers' professional
          development. Teachers are encouraged to develop creative teaching
          methodologies, innovative assessment methods, in-depth performance
          evaluations, sensitivities for deep-connect with learners and action
          research. Learning Wings extends autonomy to teachers to experiment
          and innovate. Learning Wings earmarks 10 percent of all its resources
          for teachers training and empowerment. Learning Wings also contributes
          to the fulfillment of personal goals of its teachers through a
          dedicated support system.
        </p>
        <h2 className="mask-h4">4. Enable Better Lives</h2>
        <p>
          Learning Wings believes that education opens doors to dreams and
          things unimaginable. Learning Wings students do very well when they
          leave school. University destinations include University of California
          (UCLA, UCSD), New York University (NYU), London School of Economics
          (LSE) among many others. All schools have Career Counselling services.
        </p>
        <h2 className="mask-h4">5. Support Those In Need</h2>
        <p>
          Learning Wings has award winning special outreach and scholarship
          programs that reach out to those in need. We believe in equal
          opportunity for everyone, and do our best to provide a good education
          to as many as we can. Learning Wings not only supports students
          financially but also devises personalized learning plans for those who
          are challenged in other ways. Learning Wings has award winning special
          needs programmes that aim to improve - through inclusion, education
          and advocacy - the quality of life of differently abled children.
        </p>
        <h2 className="mask-h4">6. Standards Compliant Education</h2>
        <p>
          Learning Wings has partnered with leading national and international
          accreditation boards. Learning Wings schools and teachers training
          centres are affiliated to Central Board of Secondary Education(CBSE),
          Cambridge International Exam (CIE), International Baccalaureate (IB),
          University of Cambridge Language Assessment, and more.
        </p>
        <h2 className="mask-h4">7. Improve Educational Experience</h2>
        <p>
          Learning Wings has been an early adopter of novel and unconventional
          tools time and again, like Scratch from MIT Labs. These innovative
          practices have enabled transformative and enriching experiences for
          learners. These tools have created an effective environment for
          participatory teaching and learning. Learning Wings has simplified the
          teaching-learning process by using best-in-class technologies.
          Learning Wings is also involved in the research and development of
          next generation integrated learning systems and new teaching
          methodologies.
        </p>
        <h2 className="mask-h4">8. Holistic Education</h2>
        <p>
          Learning Wings believes in all-round development of individuals. We
          focus on academics, but on the ‘non-essentials’ too. For us all
          expressions of life are valid and essential. Learning Wings students
          have daily 10 minute meditation sessions, yoga classes. Students, who
          leave Learning Wings, leave equipped to face and deal with the
          challenges of life.
        </p>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
